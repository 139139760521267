import React from "react"
import serviceone from "../../../static/images/serviceone.png"
import Data from "../../../static/images/meeting.jpg"

export default function sectionone() {
  return (
    <div className=""style={{fontFamily:'poppins'}}>
      {/* <section
        class=" px-12 lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={Machine}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                  Machine Learning and Artificial Intelligence
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Valuation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            liabilities in an insurance company by preparation
                            of regulatory & financial condition reports (FCR)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Preparation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Preparation of claims reserve estimation and
                            adequacy assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Reinsurance
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Reinsurance appropriateness and sufficiency
                            assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Analysis of claims experience of an insurance
                            company
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class=" py-12 px-4 sm:px-6 lg:px-8">
        {/* <div class="max-w-7xl mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div class="bg-white rounded-lg overflow-hidden shadow-lg p-6">
              <h2 class="text-base font-semibold leading-7 text-indigo-600">
                Our Services
              </h2>
              <h3 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Business and Management Consulting
              </h3>
              <p class="text-lg leading-8 text-gray-600 mt-5">
                RJ Actuaries & Consultants provides strategic business
                consulting services, empowering clients to make informed
                decisions and navigate the complexities of the insurance and
                risk management industry.
              </p>
              <ul class="list-disc ml-6 mb-4 mt-5">
                <li>We help companies take data-driven decisions</li>
                <li>
                  Review Business Models to make informed decisions about risk
                  management, financial planning, and other important areas
                </li>
                <li>Review of Operations of Departments</li>
                <li>Expense and Revenue Analysis</li>
                <li>
                  Recommendation and Implementation of Efficient Business
                  Processes
                </li>
              </ul>
            </div>

            <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                class="h-96 w-full transform hover:scale-105 transition ease-in-out duration-1000"
                src={Data}
                alt=""
              />
            </div>
          </div>
        </div> */}

        <div class="flex justify-center items-center">
          {/* <!-- Text Content --> */}
          <div class="max-w-xl mx-auto px-4 py-8">
            <h2 class="text-base font-semibold leading-7 text-indigo-600">
              Our Services
            </h2>
            <h2 class="text-3xl font-semibold text-blue-900 mb-4">
              Business and Management Consulting
            </h2>
            <p class="lg:text-lg leading-8 text-gray-600">
            Today’s businesses face an ever-changing landscape of operational, financial, and strategic challenges. At RJ Actuaries and Consultants, we help organizations navigate complexity through data-driven strategies, operational efficiency enhancements, and tailored business solutions that deliver measurable results.
            </p>
          
          </div>
          {/* <!-- Image --> */}
          <div class="lg:w-1/2 hidden lg:block">
            <img
              src={Data}
              alt="Image"
              class="w-full h-auto lg:mx-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto">
                <div class="text-center">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                    Our Services
                  </h2>
                 
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Strategic Risk and Financial Planning
                      </h3>
                      <p class="text-gray-600">
                      Providing actionable insights and frameworks to align risk management with long-term financial goals.         </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Operations Review and Optimization
                      </h3>
                      <p class="text-gray-600">
                      Comprehensive reviews of departmental operations to identify inefficiencies and recommend improvements for enhanced performance.          </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Expense and Revenue Analysis
                      </h3>
                      <p class="text-gray-600">
                      In-depth analysis of cost structures and revenue streams to optimize profitability and reduce unnecessary expenditures.

                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Business Model Development
                      </h3>
                      <p class="text-gray-600">
                      Helping businesses design scalable and resilient models that align with market demands and organizational goals.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Process Efficiency Implementation
                      </h3>
                      <p class="text-gray-600">
                      Streamlining workflows and processes to enhance productivity, reduce bottlenecks, and improve overall operational efficiency.
                      </p>
                    </div>
                    
                  </div>
                  {/* <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                 
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Customized Benefit Plan Design
                      </h3>
                      <p class="text-gray-600">
                      Developing sustainable and meaningful benefit plans tailored to specific organizational needs and budgets.
                      </p>
                    </div>
                    
                  </div> */}
                </div>
              </div>
            </div>
            <div class="container px-4 mx-auto mt-4">
          <p class="mb-6 text-xl text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why Chose RJAC ?
          </p>
          

          <section class="bg-white pb-6">
            {/* <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h1 class="md:text-2xl md:mb-5">
                    General and Health Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Capital and Risk Modelling</li>
                    <li>- Product design</li>
                    <li>- Pricing</li>
                    <li>- Reserving</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Liability Adequacy Testing</li>
                    <li>- Financial Condition Reports</li>
                    <li>
                      - Reinsurance optimization, appropriateness and
                      sufficiency assessment
                    </li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
                <div>
                  <h1 class=" md:text-2xl md:mb-5">
                    Life Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Risk Assessment</li>
                    <li>- Product design & filing</li>
                    <li>- Pricing</li>
                    <li>- Financial Reporting</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Reinsurance optimization</li>
                    <li>- Analysis of Surplus calculations</li>
                    <li>- Embedded value calculations</li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="max-w-7xl mx-auto">
              

              <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                {/* <!-- General and Health Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Customized Approach
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> Tailored solutions to meet the specific challenges and goals of your organization.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Data-Driven Strategies
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Leverage analytics to make informed decisions that drive growth and efficiency.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Industry Expertise
                    </h3>
                    <ul class="list-disc ml-6">
                      <li>Decades of experience across diverse sectors, ensuring practical and effective recommendations.</li>
                      
                    </ul>
                  </div>
                </div>

                
              </div>
            </div>

            {/* ......................................................... */}

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto align-middle">
                <div class="text-left">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                  Key Outcomes for Clients
                  </h2>
                  
                </div>
                {/* <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                      Improved financial stability and accuracy in projections.
                      </h3>
                     
                    </div>
                  </div>
                  

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900  text-center ">
                        Valuation Actuary
                      </h3>
                      
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                        Pricing Actuary
                      </h3>
                      
                    </div>
                  </div>

                 

                
                </div> */}
                <div >
                <ul class="list-disc ml-6">
                      <li className="text-xl">Improved operational efficiency and cost savings.
                      </li>
                      <li className="text-xl">Enhanced decision-making through actionable insights and strategic planning.</li>
                      <li className="text-xl">Scalable business processes that adapt to evolving market needs.</li>
                      
                    </ul>
                </div>
                
              </div>
              
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}
